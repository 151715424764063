import "./ComposeMessage.css"
import { Message } from "../../types/Message";
import MessageInputField from "./MessageInputField";
import { DbUser } from "../../context/AuthContext";


type ComposeMessageProps =
{
    chatId?: number | undefined;
    onCreateNewMessage: (chat: Message) => void;
    user: DbUser | null;
}

export default function ComposeMessage({ chatId, onCreateNewMessage, user }: ComposeMessageProps) 
{
    return (
        <MessageInputField chatId={chatId} user={user} onCreateNewMessage={onCreateNewMessage}></MessageInputField>
    )
}