import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
        main: "#252B48",
    },
    error: {
      main: "#d32f2f",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "#FFF",
          backgroundColor: "#252B48",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(37, 43, 72, 0.40)",
            color: "#f0f2f5"
          },
          "&:hover .MuiSvgIcon-root": {
            color: "#f0f2f5",
          },
        }
      }
    }
  },
});
