import { Button, Grid, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { ChangeEvent, useState } from 'react'
import "./MessageInputField.css";
import Cookies from 'js-cookie';
import { DbUser } from '../../context/AuthContext';
import axios from 'axios';
import { Message } from '../../types/Message';
import { UserChats } from '../../types/UserChats';

type Props = 
{
    onCreateNewMessage?: (chat: Message) => void;
    onCreateNewChat?: (chat: UserChats) => void;
    user: DbUser | null;
    chatId?: number;
}

export default function MessageInputField({ user, onCreateNewMessage, onCreateNewChat, chatId }: Props) 
{

    const token = Cookies.get("jwt");
    const [messageText, setMessageText] = useState<string>("");


    const onClickSendMessage = async () => 
    {
        if (onCreateNewMessage && !onCreateNewChat)
        {
          try
          {
              const body =
              {
                  chatId: chatId,
                  messageText: messageText
              };

              const result = await axios.post(
                  `/api/users/${user?.user_id}/chats/create`, JSON.stringify(body),
                  {
                      headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                      },
                      withCredentials: true,
                  }
              );

              if (result.status === 200) 
              {
                  onCreateNewMessage(result.data.messageWithSender);
              }
          }
          catch (error: any) {
              console.log(error.message);
          }
        }

        else if (onCreateNewChat)
        {
            try {
              const body =
              {
                  messageText: messageText,
                  recipientId: 1 //! hard coded for testing...
              };

              const result = await axios.post(
                  `/api/users/${user?.user_id}/inbox/create`, JSON.stringify(body),
                  {
                      headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                      },
                      withCredentials: true,
                  }
              );

              if (result.status === 200) 
              {
                  onCreateNewChat(result.data.user_chat);
              }
          }
          catch (error: any) 
          {
              console.log(error.message);
          }
        }
    }


    return (
    <Grid container sx={{ height: "calc(100vh - 65vh - 164px)", padding: "10px" }}>
      <Grid item xs={11} className='input-field-container'>
        <TextField variant={'filled'}
          sx={{height: '100%'}}
          value={messageText} 
          label="Message"
          multiline 
          fullWidth
          InputProps={{
            style: {
              height: "100%",
            }
          }} 
        onChange={(e: ChangeEvent<HTMLInputElement>) => setMessageText(e.target.value)}></TextField>
      </Grid>
      <Grid item xs={1} className="send-container">
          <Button variant={"contained"} endIcon={<SendIcon />} onClick={onClickSendMessage}>Send</Button>
      </Grid>
    </Grid>
  )
}