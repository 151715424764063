import { createContext, useContext, useEffect, useRef, useState } from "react";
import { UserInput } from "../pages/User/Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { UserAccount } from "../pages/User/Account";
import { Socket, io } from "socket.io-client";


export interface DbUser {
    user_id: string,
    first: string;
    last: string;
    email: string;
    phone_number: string;
}


type Shop = {
    shopName: string,
    shopId: number
}

type UserContextValue = {
    user: DbUser | null;
    shop: Shop | null;
    login: (input: UserInput) => Promise<void>;
    logout: () => void;
    setUserObject: (user: DbUser) => void;
    setShopObject: (shop: Shop) => void;
    updateUser: (userId: string, user: UserAccount) => Promise<void>;
}

interface UserProviderProps {
    children: React.ReactNode;
}

const UserContext = createContext<UserContextValue>({
    user: null,
    shop: null,
    login: async () => { },
    setUserObject: function (user: DbUser): void { },
    setShopObject: function (shop: Shop): void { },
    logout: function () { },
    updateUser: async function (userId: string, user: UserAccount): Promise<void> { },
});

export const useUser = () => {
    return useContext(UserContext);
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<DbUser | null>(null);
    const [shop, setShop] = useState<Shop | null>(null);
    const socketRef = useRef<Socket | null>(null);

    const navigate = useNavigate();
    const token = Cookies.get("jwt");
   
    useEffect(() => {

        const getUserDataFromLocalStorage = () => 
        {
            const userData = localStorage.getItem("userData");
            const shopData = localStorage.getItem("shopData");

            return {
                userData: userData ? JSON.parse(userData) : null,
                shopData: shopData ? JSON.parse(shopData) : null
            };
        };

        const { userData, shopData } = getUserDataFromLocalStorage();
        
        if (userData && shopData && token) 
        {
            setUser({ ...userData });
            setShop({ ...shopData });
            fetchUser(token);
        }

        return () => 
        {
            if (socketRef.current){
                socketRef.current?.disconnect();
                socketRef.current = null;
            }
        }    
        
    }, [token]);


    const initializeSocket = (token: string | undefined): void => 
    {
        if (!socketRef.current)
        {
            socketRef.current = io("http://detaillconnect", {
                auth: {
                    token: token
                },
                autoConnect: false
            });

            socketRef.current.on('connect', () => {
                console.log('Socket connected');
            });
        
            socketRef.current.on('connect_error', (error: Error) => {
                console.error('Socket connection error:', error);
            });

            socketRef.current.on('disconnect', () => {
                console.log('Socket disconnected');
            });

            socketRef.current.connect();
        }
    }

    const fetchUser = async(token: string) => 
    {
        try {
            const res = await axios.get('/api/verifyToken', {
                headers: { 'Authorization': `Bearer ${token}` },
                withCredentials: true,
            });

            setUser(res.data);
            initializeSocket(token);
        } 
        catch (error) 
        {
            console.log(error);
        }
    }


    const updateUser = async (userId: string, user: UserAccount): Promise<void> => {
        try {

            const token = Cookies.get("jwt");

            const response = await axios.put(`/api/account/${userId}`, user, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {

                const { first, last, email, phone_number, user_id } = response.data.user;

                const updatedUserData = {
                    user_id,
                    first,
                    last,
                    email,
                    phone_number,
                };

                setUser(updatedUserData);

                localStorage.setItem("userData", JSON.stringify(updatedUserData))
            } else {
                console.log("Updated Failed. Status code: ", response.status);
            }
        } catch (error) {
            console.log(error);
        }
    }


    const login = async (input: UserInput): Promise<void> => {
        try {
            const body = { input };
            const res = await axios.post(
                "/api",
                JSON.stringify(body),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            const { user, shop, token } = res.data;

            if (user && token)
            {
                storeUserData(user, shop, token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

                initializeSocket(token);

                navigate("/dashboard");
            }
            else 
            {
                navigate("/");
            }
        }
        catch (error: any) {
            console.log(error.response);
        }
    };


    const storeUserData = (user: DbUser, shop: Shop, token: string) => 
    {
        localStorage.setItem("shopData", JSON.stringify(shop));
        localStorage.setItem("userData", JSON.stringify(user));

        Cookies.set("jwt", token);
        Cookies.set("navOpened", "true");
        Cookies.set("selectedTab", "Dashboard");

        setUser({ ...user })
        setShop({ ...shop });
    }


    const logout = () => {
        setUser(null);
        setShop(null);
        Cookies.remove("jwt");
        Cookies.remove("navOpened");
        Cookies.remove("selectedTab");
        localStorage.removeItem("userData");
        localStorage.removeItem("shopData");
       
        if (socketRef.current)
        {
            try 
            {
                socketRef.current.disconnect();
                socketRef.current = null;    
            } 
            catch (error) 
            {
                console.log("Error disconnecting socket:", error);
            }
        }
    }

    const setUserObject = (info: DbUser) => {
        setUser(info);
    }

    const setShopObject = (info: Shop) => {
        setShop(info);
    }

    const contextValue: UserContextValue = { user, shop, login, logout, setUserObject, updateUser, setShopObject };

    return <UserContext.Provider value={contextValue}>
        {children}
    </UserContext.Provider>
};
