import { Grid } from "@mui/material"

import Cookies from "js-cookie"
import { UserChats } from "../../types/UserChats"
import UserChatList from "./UserChatList"
import { DbUser } from "../../context/AuthContext"
import "./UserInbox.css";
import { Message } from "../../types/Message"
import { ShopAdmin } from "../../types/ShopAdmin"
import ChatList from "./ChatList"
import NewChatList from "./NewChatList"


type InboxProps =
{
    userChats: UserChats[] | undefined;
    onSelectedChat: (chat_id: number) => void;
    selectedChat: UserChats | undefined;
    selectedShop: ShopAdmin | undefined;
    user: DbUser | null;
    onUpdateFavorite: (updated_chat: UserChats) => void;
    onDeleteChat: (chat_id: number) => void;
    onCreateNewMessage: (chat: Message) => void;
    onCreateNewChat: (chat: UserChats) => void;
    composeChat: boolean;
    messages: Message[];
}


export default function UserInbox({ userChats, onSelectedChat, selectedChat, user, onUpdateFavorite, onDeleteChat, onCreateNewMessage, composeChat, onCreateNewChat, messages }: InboxProps) {

    const open = Cookies.get("navOpened");

    return (
        <Grid container sx={{
            minWidth: "90vw", m: open === "true" ? "0 0 0 20ww" : "0 0 0 0",
            transition: "margin 0.3s ease-in-out", height: 'calc(100vh - 164px)', backgroundColor: "#f0f2f5"
        }}>
            <Grid item xs={4} sm={4} md={4} lg={4} sx={{ backgroundColor: "white" }}>
                <UserChatList userChats={userChats} onSelectedChat={onSelectedChat} onUpdateFavorite={onUpdateFavorite} onDeleteChat={onDeleteChat} />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} sx={{ borderLeft: "3px solid #f0f2f5" }}>
                {
                    !composeChat ? 
                    <>
                        {
                            selectedChat && 
                            <ChatList chats={selectedChat} user={user} onCreateNewMessage={onCreateNewMessage} messages={messages}></ChatList>
                        }
                    </>
                    : 
                    <> 
                        <NewChatList chats={selectedChat} user={user} onCreateNewChat={onCreateNewChat} messages={messages}></NewChatList>
                    </>
                }
            </Grid>
        </Grid>
    )
}