import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormLabel, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'

type Props = {
    openDialog: boolean;
    handleCloseDialog: () => void;
    fullScreen: boolean;
    handleSendEmail: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
};  

export default function ResetPassword({ openDialog, handleCloseDialog, handleSendEmail }: Props) 
{

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));


    const sendResetEmail = () => 
    {
      try 
      {
        
      } 
      catch (error) {
        
      }
    }


    return (
    <React.Fragment>
        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>
            {`Reset Password`}
          </DialogTitle>
          <DialogContent sx={{margin: '0'}}>
            <DialogContentText>
            To reset your password, please enter the email address associated with your account.
            We will send you a link to reset your password.
            </DialogContentText>
            <Grid container paddingTop={3} paddingBottom={3}>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>  
                <FormLabel sx={{paddingRight: 1}}>Email</FormLabel>
                <TextField id="email" name="Email" fullWidth></TextField> 
              </Grid>
            </Grid>        
          </DialogContent>
          <DialogActions>
            <Grid container>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between'}}>
                    <Button variant='outlined' autoFocus onClick={handleCloseDialog}>Close</Button>
                    <Button type='submit' variant='contained'>Send Reset Link</Button>
                </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
    </React.Fragment>
  )
}