import MessageInputField from './MessageInputField'
import { UserChats } from '../../types/UserChats';
import { DbUser } from '../../context/AuthContext';

type Props = {
  user: DbUser | null;
  onCreateNewChat: (chat: UserChats) => void;
}

export default function ComposeChat({ user, onCreateNewChat }: Props) 
{   
    return (
        <MessageInputField user={user} onCreateNewChat={onCreateNewChat}></MessageInputField>
    )
}