import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import GarageCard from "../../assets/garage-card.jpg";
import { CarInputError } from "../../pages/User/Garage";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface AddCarProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  errors: CarInputError;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formData: any;
}

export default function AddCarCard({
  onSubmit,
  errors,
  handleInputChange,
  formData,
}: AddCarProps) {
  return (
    <Box
      height={errors.year || errors.make || errors.model ? "550px" : "475px"}
    >
      <Card
        sx={{height: "inherit", boxShadow: 5}}
        component={"form"}
        noValidate
        onSubmit={onSubmit}
      >
        <CardMedia component="img" height={"140"} image={GarageCard} />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component={"div"}
            textAlign={"center"}
          >
            Car Information
          </Typography>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <TextField
                onChange={handleInputChange}
                value={formData.Year}
                label="Year"
                name="Year"
                fullWidth
                required
                error={errors.year}
                helperText={errors.year ? "This field is required" : ""}
              >
                Year
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleInputChange}
                value={formData.Make}
                label="Make"
                name="Make"
                fullWidth
                required
                error={errors.make}
                helperText={errors.make ? "This field is required" : ""}
              >
                Make
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                onChange={handleInputChange}
                value={formData.Model}
                label="Model"
                name="Model"
                fullWidth
                required
                error={errors.model}
                helperText={errors.model ? "This field is required" : ""}
              >
                Model
              </TextField>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex" }}>
              <Button type="submit" variant="contained" fullWidth endIcon={<AddCircleOutlineIcon />}>
                Add Car
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
