import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import ProfileAvatar from "../../assets/profile-avatar.png";
import RoundedProfilePicture from "../../components/ProfilePicture/RoundedProfilePicture";
import { useUser } from "../../context/AuthContext";

import "./Account.scss";
import Cookies from "js-cookie";

export interface UserAccount 
{
  first: string,
  last: string,
  email: string,
  phone_number: string,
}

export default function Account() 
{
  const navigate = useNavigate();
  const { user, updateUser } = useUser();
  const [updatedUser, setUpdatedUser] = useState({
    first: user?.first, last: user?.last, email: user?.email,
    phone_number: user?.phone_number
  } as UserAccount);

  const [edit, setEdit] = useState<boolean>(false);
  const token = Cookies.get("jwt");
  const { userId } = useParams();

  const handleEditClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (e.currentTarget.textContent === 'Save Changes' && userId) {
      await updateUser(userId, updatedUser);
    }
    setEdit((value) => !value);
  }

  useEffect(() => 
  {
    if (!token || !user) {
      navigate("/");
    }
  }, [user, token, navigate]);


  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
      className="account-root"
    >
      <Box
        sx={{
          height: "inherit",
          padding: "80px 20px 0 20px",
          margin: "0 0 0 60px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <RoundedProfilePicture
          imageURI={ProfileAvatar}
        ></RoundedProfilePicture>
        <Typography component={"h1"} variant="h5" paddingTop={"1rem"}>
          Account Information
        </Typography>
        <Box component="form" noValidate sx={{ mt: 3 }} className="account">
          {updatedUser && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label={"First Name"}
                  value={updatedUser.first || ''}
                  InputProps={{
                    readOnly: !edit,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUpdatedUser({ ...updatedUser, first: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  name="lastName"
                  label={"Last Name"}
                  InputProps={{
                    readOnly: !edit,
                  }}
                  value={updatedUser.last || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUpdatedUser({ ...updatedUser, last: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  id="email"
                  label={"Email"}
                  value={updatedUser.email || ''}
                  name="email"
                  InputProps={{
                    readOnly: true,
                  }}
                  disabled
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  id="phoneNumber"
                  InputProps={{
                    readOnly: !edit,
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setUpdatedUser({ ...updatedUser, phone_number: e.target.value })
                  }
                  value={updatedUser.phone_number || ''}
                />
              </Grid>
            </Grid>
          )}
          <Button
            type="submit"
            fullWidth
            variant={edit ? "outlined" : "contained"}
            sx={{ mt: 3, mb: 2 }}
            onClick={handleEditClick}
          >
            {
              edit ? "Save Changes" : "Update Account"
            }
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
