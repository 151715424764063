import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { ReactNode, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import DashboardIcon from "@mui/icons-material/Dashboard";
import GarageIcon from "@mui/icons-material/Garage";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ReceiptIcon from '@mui/icons-material/Receipt';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import EmailIcon from '@mui/icons-material/Email';
import Cookies from 'js-cookie';

type Props =
    {
        tabName: string;
        selectedTab: string | undefined;
        navigationPath: string;
        open: string | undefined;
        setSelectedTab: (tabName: string) => void;
    }

const renderTabIcon = (tabName: string): ReactNode => 
{
    switch (tabName)
    {
        case "Dashboard":
            return <DashboardIcon />
        case "Garage":
            return <GarageIcon />
        case "Calendar": 
            return <CalendarMonthIcon />
        case "Invoices":
            return <ReceiptIcon />
        case "Inbox": 
            return <EmailIcon />
        case "Account":
            return <ManageAccountsIcon />
        default:
            <></>
    }
}


export default function SideNavTabItem({ tabName, navigationPath, open, selectedTab, setSelectedTab }: Props) {

    const navigate = useNavigate();

    return (
        <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                    backgroundColor: selectedTab === tabName ? "rgba(37, 43, 72, 0.8)" : "transparent",
                    "&:hover": {
                        backgroundColor: selectedTab === tabName ? "rgba(37, 43, 72, 0.8)" : "rgba(37, 43, 72, 0.4)",
                    },
                    color: selectedTab === tabName ? "#f0f2f5" : 'none'
                }}
                onClick={() => 
                {
                    Cookies.set("selectedTab", tabName);
                    navigate(`${navigationPath}`);
                    setSelectedTab(tabName);
                }}
            >
            <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: selectedTab === tabName ? "#f0f2f5" : 'none'
                }}
              >
                { renderTabIcon(tabName) }
              </ListItemIcon>
              <ListItemText
                primary={tabName}
                sx={{ opacity: open ? 1 : 0 }}
              >
              </ListItemText>
            </ListItemButton>
        </ListItem>
    )
}