import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ContactlessIcon from "@mui/icons-material/Contactless";
import { useNavigate } from "react-router-dom";
import "./Navbar.scss";
import SideNav from "../SideNav/SideNav";
import { useUser } from "../../context/AuthContext";
import useWindowDimensions from "../../hooks/WindowDimensions";
import Cookies from "js-cookie";

export const NAVHEIGHT = 64;

interface NavbarProps {
  handleClose: () => void;
}


export default function Navbar({ handleClose }: NavbarProps) {
  const { width } = useWindowDimensions();
  const [loading, setLoading] = useState<boolean>(true);
  const { user, shop } = useUser();
  const navigate = useNavigate();
  const token = Cookies.get("jwt");
  const open = Cookies.get("navOpened");

  const handleGarageClick = () => 
  {
    navigate("/");
  };

  
  useEffect(() => 
  {
    setLoading(false);
  }, [user])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Typography
              variant="h6"
              marginLeft={!token ? "0" : open === "true" ? "240px" : "60px"}
              sx={{ transition: "margin-left 0.3s ease-in-out" }}
            >
              Detail Connect
            </Typography>
            <ContactlessIcon sx={{ marginLeft: "1rem", 
              fontSize: "2rem", 
              display: (open === "true" && width < 600) ? 'none': '',
              transition: "display 0.3s ease-in-out",
            }} />
          </Box>
          <Box>
            <Typography>
              {
                shop && <>
                  {shop?.shopName}
                </>
              }
            </Typography>
          </Box>
          {
            loading ? <></> : !user ? <Button
              sx={{
                display: { xs: 'none', sm: 'none', md: 'flex' }
              }}
              variant="outlined"
              color="inherit"
              startIcon={<DirectionsCarIcon />}
              onClick={handleGarageClick}
            >
              My Garage
            </Button> : <></>
          }
        </Toolbar>
      </AppBar>
      {token && <SideNav open={open} handleClose={handleClose} />}
    </Box>
  );
}
