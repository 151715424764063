import { DbUser } from '../../context/AuthContext';
import { Message } from '../../types/Message'
import "./MessageChat.css";
import { Typography } from '@mui/material';

type Props = {
  message: Message;
  user: DbUser | null;
}

export default function MessageChats({ message, user }: Props) 
{
  return (
    <div className='chat-message'>
    {
        user &&
        <div className={`chat-message-container ${parseInt(user.user_id) === message.sender.user_id ? 'sender' : 'recipient'}`}>
            <div className={`chat-message ${parseInt(user.user_id) === message.sender.user_id ? 'sender' : 'recipient'}`}>
                  <Typography variant='body2' fontFamily={"arial"}>
                    {message.body}
                  </Typography>
                <div className='message-timestamp'>
                  {new Date(message.date_sent).toLocaleTimeString()}
                </div>
            </div>
        </div>
      }
    </div>
  )
}