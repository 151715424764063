import { useEffect, useState } from "react";
import { useUser } from "../../context/AuthContext";
import { Box, Button, Grid, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import AppointmentDialog from "../../components/Dialog/Appointment/AppointmentDialog";
import { CalendarAppointment } from "../../types/CalendarAppointment";
import { ServiceDetails } from "../../types/ServiceDetails";
import Cookies from "js-cookie";

interface DashboardProps {

}

export default function Dashboard({}: DashboardProps) 
{

  const { user } = useUser();
  const token = Cookies.get("jwt");
  const open = Cookies.get("navOpened");
  const [appointments, setAppointments] = useState<CalendarAppointment[]>([]);
  const [targetAppointment, setTargetAppointment] = useState<
    CalendarAppointment | undefined
  >(undefined);
  const [openAppDialog, setOpenAppDialog] = useState<boolean>(false);
  const [serviceDetails, setServiceDetails] = useState<
    ServiceDetails | undefined
  >();


  const loadAppointments = async () => 
  {
    try 
    {
      if (user && user.user_id) 
      {
        const response = await axios.get(
          `/api/users/${user.user_id}/calendar`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { appointments } = response.data;
        setAppointments(appointments);
      }
    } catch (error) 
    {
      console.log(error);
    }
  };


  const columns: GridColDef[] = 
  [
    {
      field: "id",
      headerName: "ID",
      width: 70,
    },
    {
      field: "date",
      headerName: "Date",
      width: 240,
    },
    {
      field: "year",
      headerName: "Year",
      width: 130,
    },
    {
      field: "make",
      headerName: "Make",
      width: 150,
    },
    {
      field: "model",
      headerName: "Model",
      width: 250,
    },
    {
      field: "action",
      headerName: "",
      width: 100,
      renderCell(params) {
        return (
          <Button
            onClick={() => handleAppointmentRowClick(params.id)}
            startIcon={<VisibilityIcon />}
            variant="contained"
          >
            View
          </Button>
        );
      },
    },
  ];


  const rows = appointments.map((appointment) => ({
    id: appointment.appointment_id,
    date: new Date(appointment.appointment_date).toLocaleString(),
    year: appointment.Car.year,
    make: appointment.Car.make,
    model: appointment.Car.model,
  }));


  const fetchService = async (serviceId: number) => {
    try {
      const result = await axios.get(
        `/api/services/${serviceId}`
      );
      const { service } = result.data;
      setServiceDetails({
        service_description: service.service_description,
        service_id: service.service_id,
        service_name: service.service_name,
      });
    } catch (error) {
      console.log(error);
    }
  };


  const handleAppointmentRowClick = async (appointment: GridRowId) => {
    try {
      const appointmentId = parseInt(appointment.toString());
      const foundAppointment = appointments.find(
        (app) => app.appointment_id === appointmentId
      );
      if (foundAppointment) {
        setTargetAppointment(foundAppointment);
        await fetchService(foundAppointment.service_id);
        setOpenAppDialog(!openAppDialog);
      }
    } catch (error) {
      console.log("Error fetching service: ", error);
    }
  };


  const handleClose = () => {
    setTargetAppointment(undefined);
    setOpenAppDialog(false);
  };


  useEffect(() => {
    if (token && user) {
      loadAppointments();
    }
  }, [token, user]);


  const handleDelete = async (appointment: CalendarAppointment | undefined) => {
    try {
      const result = await axios.delete(
        `/api/appointments/${appointment?.appointment_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (result) {
        const updatedAppointments = appointments.filter(
          (app) => app.appointment_id !== appointment?.appointment_id
        );
        setAppointments(updatedAppointments);
        handleClose();
      }
    } catch (error) {
      console.log(error);
    }
  };


  const reloadUpdatedAppointment = (
    updatedAppointment: CalendarAppointment | undefined
  ) => {
    if (updatedAppointment) {
      setAppointments((prevAppointments) => {
        return prevAppointments.map((app) =>
          app.appointment_id === updatedAppointment.appointment_id
            ? updatedAppointment
            : app
        );  
      });
      setTargetAppointment(undefined);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Grid
        container
        sx={{
          padding: open === "true" ? "10px 0 0 260px" : "10px 0 0 90px",
          transition: "padding-left 0.3s ease-in-out",
        }}
      >
        <Grid item xs={12} md={7} lg={7}>
          <Box>
            <Typography variant="h5" padding={1}>
              Appointments
            </Typography>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
                columns: {
                  columnVisibilityModel: {
                    id: false,
                  },
                },
              }}
              pageSizeOptions={[5, 10]}
              rowSelection={false}
            ></DataGrid>
          </Box>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <Box>
            <Typography variant="h5" padding={1}>
              Notifications
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <AppointmentDialog
        handleClose={handleClose}
        setOpenAppDialog={setOpenAppDialog}
        open={openAppDialog}
        appointment={targetAppointment}
        reloadAppointment={reloadUpdatedAppointment}
        serviceDetails={serviceDetails}
        handleDelete={() => handleDelete(targetAppointment)}
      />
    </Box>
  );
}
