import React, { useState } from 'react'
import { ShopAdmin } from '../../types/ShopAdmin'
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { ChangeEvent } from 'preact/compat';
import MessageInputField from './MessageInputField';
import ComposeMessage from './ComposeMessage';
import { Message } from '../../types/Message';
import { UserChats } from '../../types/UserChats';
import MessageRenderer from './MessageRenderer';
import { DbUser } from '../../context/AuthContext';
import ComposeChat from './ComposeChat';

type Props = {
  chats: UserChats | undefined;
  user: DbUser | null;
  onCreateNewChat: (chat: UserChats) => void;
  messages: Message[];
}

export default function NewChatList({ chats, user, onCreateNewChat, messages }: Props) 
{

  const [shopAdmin, setShopAdmin] = useState<string>("");
  

  const onChangeShopAdmin = (event: SelectChangeEvent) =>
  {
    setShopAdmin(event.target.value as string);
  } 


  return (
    
    <div>
      <MessageRenderer chats={chats} user={user} messages={messages}></MessageRenderer>
      <ComposeChat user={user} onCreateNewChat={onCreateNewChat}></ComposeChat>
    </div>
  )
}