import { UserChats } from '../../types/UserChats'
import MessageChat from './MessageChat';
import { DbUser } from '../../context/AuthContext';
import { Message } from '../../types/Message';

type Props = {
    chats: UserChats | undefined;
    user: DbUser | null;
    messages: Message[];
}

export default function MessageRenderer({ messages, user }: Props) 
{

  return (
    <div className="message-sender-container">
      <div className="messages-container">
          {messages.map((message) => (
              <MessageChat user={user} key={message.message_id} message={message} />
          ))}
      </div>
  </div>
  )
}