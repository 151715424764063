import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { CalendarAppointment } from '../../../types/CalendarAppointment';

type DeleteAppointmentProps = {
    appointment: CalendarAppointment,
    open: boolean,
    handleClose: () => void;
    handleCloseParent: () => void;
    handleDelete: (appointment: CalendarAppointment | undefined) => void;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props}></Slide>
});

export default function DeleteAppointmentDialog({ open, handleClose, handleDelete, appointment }: DeleteAppointmentProps) {

  return (
    <div>
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        >
          <DialogTitle>Delete Appointment Confirmation</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this appointment?</DialogContentText>
            <br></br>
            <DialogContentText fontWeight={600}>*Action cannot be undone.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button startIcon={<CloseIcon />} variant="contained" onClick={handleClose}>
              Close
            </Button>
            <Button startIcon={<DeleteIcon />} variant="contained" onClick={() => handleDelete(appointment)}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}