import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useUser } from '../../context/AuthContext';
import UserInbox from '../../components/Inbox/UserInbox';
import Cookies from 'js-cookie';
import InboxHeader from '../../components/Inbox/InboxHeader';
import { Box } from '@mui/material';
import { UserChats } from '../../types/UserChats';
import { Message } from '../../types/Message';
import { ShopAdmin } from '../../types/ShopAdmin';


type Props = {}

export default function InboxPage({}: Props) 
{
    const { user, shop } = useUser();
    const token = Cookies.get("jwt");
   
    const [composeChat, setComposeChat] = useState<boolean>(true);

    const [selectedChat, setSelectedChat] = useState<UserChats | undefined>(undefined);
    const [messages, setMessages] = useState<Message[]>([]);

    const [userChats, setUserChats] = useState<UserChats[]| undefined>([]);

    const [selectedShop, setSelectedShop] = useState<ShopAdmin | undefined>(undefined);

    const [pageIndex, setPageIndex] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);


   useEffect(() => 
   {
      if (token && user && shop) 
      {
         loadChats();
         loadShopAdmins();
      }
   }, [token, user, selectedChat]);


   useEffect(() => 
   {
      if (user && selectedChat && token)
      {
         loadMessages();
      }
   }, [selectedChat, token])


   const loadChats = async() => 
   {
      try {
         if (user && user.user_id)
         {

            const response = await axios.get(
               `/api/users/${user.user_id}/chats`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`
                  }
               }
            );

            const { user_chats } = response.data;
            setUserChats(user_chats);
         }
      } 
      catch (error) 
      {
            
      }
   };


   const loadMessages = async() => 
   {
      try {
         const result = await axios.get(`/api/users/${user?.user_id}/chats/${selectedChat?.user_chat_id}/messages`, {
            headers: {
               Authorization: `Bearer ${token}`
            },
            params: {
               pageIndex,
               limit: pageSize
            }       
         });

         const { messages } = result.data;
         setMessages(messages);
      } 
      catch (error) {
         
      }
   }


   const loadShopAdmins = async () => 
   {
      try {
         if (user && shop)
         {             
            const response = await axios
               .get(`/api/users/${user.user_id}/shops/${shop.shopId}/admins`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                  },
               })
               .then((response) => 
               {
                  setSelectedShop(response.data.shop);
               }
            );   
         }
   }
      catch (error)
      {
         console.log(error);
      }
   }


   const onCreateNewMessage = (new_message: Message) => 
   {
      if (!selectedChat || !user) return;

      setMessages(prevMessages => [new_message, ...prevMessages]);
   }
   

   const onComposeChat = () => 
   {
      setComposeChat(true);
      setSelectedChat(undefined);  
      setMessages([]);   
   }


   const onCreateNewChat = (chat: UserChats) => 
   {
      setSelectedChat(chat);
   }
  

   const onChatSelect = (chat_id: number) => {
      setComposeChat(false);
      const targetChat = userChats?.find(chat => chat.user_chat_id === chat_id);
      setSelectedChat(targetChat);
   }


   const onUpdateFavorite = (updated_chat: UserChats) => {
      const updatedUserChats: UserChats[] | undefined = userChats?.map((chat: UserChats) => {
         if (chat.user_chat_id === updated_chat.user_chat_id) {
            return {
               ...chat,
               is_favorite: updated_chat.is_favorite
            };
         }
         return chat;
      });

      setUserChats(updatedUserChats);
   }


   const onDeleteChat = (chat_id: number) => {
      const updatedChats = userChats?.filter((chat) => chat.user_chat_id !== chat_id);
      setUserChats(updatedChats);
      setSelectedChat(undefined);
   }


   return (
      <Box width={"100%"}>
         <InboxHeader onComposeChat={onComposeChat} />
         <UserInbox 
            user={user} 
            userChats={userChats} 
            onSelectedChat={onChatSelect} 
            selectedChat={selectedChat} 
            messages={messages}
            onUpdateFavorite={onUpdateFavorite} 
            onDeleteChat={onDeleteChat} 
            onCreateNewMessage={onCreateNewMessage}
            composeChat={composeChat}
            selectedShop={selectedShop}
            onCreateNewChat={onCreateNewChat}
         ></UserInbox>
      </Box>
   )
}