import {
  Avatar,
  Box,
  Button,
  Grid,
  InputAdornment,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import React, { useEffect, useState } from "react";
import axios from "axios";
import porscheSide from "../../assets/porsche.jpg";
import "./Signup.scss";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/AuthContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import Cookies from "js-cookie";

type Props = {};

export interface NewUserInput {
  first: FormDataEntryValue | null;
  last: FormDataEntryValue | null;
  email: FormDataEntryValue | null;
  phone_number: FormDataEntryValue | null;
  password: FormDataEntryValue | null;
  confirm: FormDataEntryValue | null;
  date_of_birth: string | undefined;
  code: FormDataEntryValue | null;
}

export default function Signup({}: Props) {
  const [emailError, setEmailError] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [shopError, setShopError] = useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<Dayjs | null>(null);
  const { setUserObject, setShopObject } = useUser();
  const navigate = useNavigate();
  const token = Cookies.get("jwt");

  const handleCreateAccount = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const input: NewUserInput = {
      first: data.get("First"),
      last: data.get("Last"),
      email: data.get("Email"),
      phone_number: data.get("Phone"),
      password: data.get("Password"),
      confirm: data.get("Confirm"),
      date_of_birth: dateOfBirth?.format("YYYY-MM-DD"),
      code: data.get("ShopId"),
    };

    
    if (validateInput(input)) 
    {
      try 
      {
        const body = { input };

        await axios.post(
          "/api/signup",
          JSON.stringify(body),
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then(async(result) => 
          {

            const { user, shop, token} = result.data;

            if (user && shop)
            {
              localStorage.setItem("jwt", token);
              localStorage.setItem("shopData", JSON.stringify(shop));
              localStorage.setItem("userData", JSON.stringify(user));

              Cookies.set("navOpened", "true");
              Cookies.set("selectedTab", "Dashboard");

              setUserObject({...user });
              setShopObject({...shop });
            }

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        })
        .catch((error) => 
        {
          console.log(error);
        })
        .finally(() => {
          navigate("/dashboard");
        });

      }
      catch (error: any) {
        const message = error.response.data.errors.message;
        if (message === "Shop does not exist.") {
          setShopError(message);
        }
        if (message === "User with email already exists.") {
          setEmailError(message);
        }
      }
    } else {
      setPasswordError(true);
      setConfirmError(true);
    }
  };

  const validateInput = (data: NewUserInput): boolean => {
    return data.password === data.confirm ? true : false;
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    setDateOfBirth(newValue);
  };

  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  function Copyright(props: any) {
    return (
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        {...props}
      >
        {"Copyright © "}
        <Link color="inherit" href="https://mui.com/">
          Detail Connect
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    );
  }

  return (
    <Grid container component={"main"} sx={{ height: "calc(100vh - 64px" }}>
      <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
        <Box
          className="fullWidthOnSmall"
          sx={{
            backgroundColor: "#f0f2f5",
            height: "inherit",
            justifyContent: "center",
            alignItems: "baseline",
            display: "flex",
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleCreateAccount}
            sx={{
              my: 15,
              mx: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              columnSpacing={2}
              rowSpacing={2}
              sx={{
                textAlign: "center",
                padding: "1rem",
              }}
            >
              <Grid item xs={12} md={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Avatar sx={{ bgcolor: "#8B008B" }}>
                    <LockPersonIcon />
                  </Avatar>
                </Box>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography variant="h5">Create Account</Typography>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  label="First"
                  required
                  fullWidth
                  name="First"
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  label="Last"
                  required
                  fullWidth
                  name="Last"
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type="email"
                  label="Email Address"
                  required
                  fullWidth
                  name="Email"
                  error={emailError}
                  helperText={emailError}
                  onChange={() => setEmailError(false)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disableFuture
                    label={"Date of Birth"}
                    onChange={handleDateChange}
                    sx={{ width: "100%" }}
                  ></DatePicker>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  label="Phone Number"
                  required
                  fullWidth
                  name="Phone"
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  type="password"
                  label="Password"
                  required
                  fullWidth
                  name="Password"
                  error={passwordError}
                  onChange={() => setPasswordError(false)}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  required
                  fullWidth
                  name="Confirm"
                  error={confirmError}
                  onChange={() => setConfirmError(false)}
                ></TextField>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label="Shop ID"
                  required
                  fullWidth
                  name="ShopId"
                  error={shopError}
                  helperText={shopError}
                  onChange={() => setShopError(false)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">#</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                justifyContent={"flex-end"}
                display={"flex"}
              >
                <Button type="submit" variant={"contained"} color="primary">
                  Create Account
                </Button>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={6}
        sx={{
          backgroundImage: `url(${porscheSide})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Grid>
    </Grid>
  );
}
