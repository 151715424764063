import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import AddCarCard from "../../components/Car/AddCarCard";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import DeleteIcon from "@mui/icons-material/Delete";

import "./Garage.scss";
import ScheduleAppointment from "../../components/Dialog/ScheduleAppointment/ScheduleAppointment";
import dayjs, { Dayjs } from "dayjs";
import { useUser } from "../../context/AuthContext";
import Cookies from "js-cookie";

export interface UserCarInput {
  year: FormDataEntryValue | null;
  make: FormDataEntryValue | null;
  model: FormDataEntryValue | null;
}

export interface CarInputError {
  year: boolean;
  make: boolean;
  model: boolean;
}

export interface Car {
  car_id: string;
  year: string;
  make: string;
  model: string;
}

export type ServiceName = {
  service_id: number,
  service_name: string,
}

export type GarageProps = {
}

export default function Garage({  }: GarageProps) 
{ 
  const [open, setOpen] = useState<boolean>(false);
  const navOpened = Cookies.get("navOpened");
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [snackMessage, setSnackMessage] = useState<string>("");
  const navigate = useNavigate();
  const token = Cookies.get("jwt");
  const [formData, setFormData] = useState({
    Year: "",
    Make: "",
    Model: "",
  });
  const [errors, setErrors] = useState<CarInputError>({
    year: false,
    make: false,
    model: false,
  });
  const [cars, setCars] = useState<Car[]>([]);
  const { shop } = useUser();
  const { userId } = useParams();
  const [appointmentDate, setAppointmentDate] = useState<Dayjs | null>(
    null
  );
  const [appointmentTime, setAppointmentTime] = useState<Dayjs | null>(
    null
  );
  const [notes, setNotes] = useState<string>("");
  const [carId, setCarId] = useState<string>("");
  const [selectedService, setSelectedService] = useState<ServiceName | null>();
  const [services, setServices] = useState<ServiceName[]>([]);


  const handleAddCar = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const validationErrors = {
      year: formData.Year === "",
      make: formData.Make === "",
      model: formData.Model === "",
    };

    setErrors(validationErrors);

    if (Object.values(validationErrors).some((error) => error)) {
      return;
    }

    const input: UserCarInput = {
      year: formData.Year,
      make: formData.Make,
      model: formData.Model,
    };

    try {
      const body = { input };
      const token = Cookies.get("jwt");
      
      const result = await axios.post(
        `/api/users/${userId}/garage/cars`,
        JSON.stringify(body),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          withCredentials: true,
        }
      );

      setFormData({
        Year: "",
        Make: "",
        Model: "",
      });

      const newCar = result.data.newCar;
      setCars([...cars, newCar]);
      setSnackMessage("Car Successfully Added");
      setOpenSnack(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (date: Date) => 
  {
    setAppointmentDate(dayjs(date));
  };


  const handleConfirmAppointment = async() => {
    try {
        
      if (appointmentDate && appointmentTime && selectedService) {

        const appointmentDateAndTime = appointmentDate
        .set('hour', appointmentTime.hour())
        .set('minute', appointmentTime.minute())
        .set('second', appointmentTime.second())

        const body = { appointmentDateAndTime, carId, userId, notes, shopId: shop?.shopId, serviceId: selectedService.service_id };

        const result = await axios.post(`/api/users/${userId}/garage/appointments`, 
        JSON.stringify(body), 
        {
          headers: { "Content-Type": "application/json" }
        })

        if (result.status === 200) {
          setAppointmentDate(null);
          setAppointmentTime(null);
          setSelectedService(null);
          setOpen(false);
        }
      }   
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCars = async () => {
    try {
      const token = Cookies.get("jwt");
      const response = await axios.get(
        `/api/users/${userId}/garage/cars`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      const { cars } = response.data;

      setCars(cars);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSnackClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackMessage("");
    setOpenSnack(false);
  };

  const handleDeleteClick = async (carId: GridRowId) => {
    try {
      const response = await axios.delete(
        `/api/users/${userId}/garage/cars/${carId.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedCars = cars.filter((car) => car.car_id !== carId);
        setSnackMessage("Car Successfully Deleted");
        setOpenSnack(true);
        setCars(updatedCars);
      } else {
        console.log("Delete failed", response.status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDialog = (id: GridRowId) => {
    loadShopServices();
    setCarId(id.toString());
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setAppointmentDate(null);
    setAppointmentTime(null);
    setSelectedService(null);
    setOpen(false);
  };

  const loadShopServices = async() => {
    try {
      const token = Cookies.get("jwt");
      await axios.get(`/api/shops/${shop?.shopId}/services`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      }).then((result) => {
        const { services } = result.data;
        setServices(services);
      })
    } 
    catch (error) {
      
    }
  }

  const handleServiceChange = (selection: ServiceName) => {
    setSelectedService(selection);
  }


  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      width: 0,
      disableColumnMenu: true,
    },
    {
      field: "year",
      headerName: "Year",
      width: 140,
    },
    {
      field: "make",
      headerName: "Make",
      width: 140,
    },
    {
      field: "model",
      headerName: "Model",
      width: 240,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            startIcon={<LocalCarWashIcon />}
            onClick={() => handleOpenDialog(params.id)}
          >
            Schedule
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 50,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => handleDeleteClick(params.id)}>
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => 
  {
  }, [selectedService])


  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
    fetchCars();
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Box
        sx={{
          height: "inherit",
          padding: navOpened === "true" ? "10px 0 0 200px" : "10px 0 0 20px",
          transition: "padding-left 0.3s ease-in-out",
          margin: "0 0 0 60px",
          display: "flex",
        }}
      >
        <Box className="GarageContainer">
          <Box className={"AddCarContainer"}>
            <Box className={"AddCarInner"}>
              <AddCarCard
                onSubmit={handleAddCar}
                errors={errors}
                handleInputChange={handleInputChange}
                formData={formData}
              ></AddCarCard>
            </Box>
          </Box>
          <Box className="CarListContainer">
            <Box className="CarListInner">
              <Box>
                <Typography
                  variant="h5"
                  sx={{ pb: "1rem", textAlign: "center" }}
                >
                  My Cars
                </Typography>
                <Divider></Divider>
                <DataGrid
                  getRowId={(row) => row.car_id}
                  rows={cars}
                  rowSelection={false}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <ScheduleAppointment
          open={open}
          handleClose={handleCloseDialog}
          handleDateChange={handleDateChange}
          setNotes={setNotes}
          setTime={setAppointmentTime}
          handleConfirmAppointment={handleConfirmAppointment}
          selectedService={selectedService ?? null}
          services={services ?? []}
          handleServiceChange={handleServiceChange}
        ></ScheduleAppointment>
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {snackMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Box>
  );
}
