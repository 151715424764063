import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/User/Login";
import Navbar from "./components/Navbar/Navbar";
import Signup from "./pages/User/Signup";
import Account from "./pages/User/Account";
import Calendar from "./pages/User/Calendar";
import Dashboard from "./pages/User/Dashboard";
import Garage from "./pages/User/Garage";
import { useState } from "react";
import ProtectedRoutes from "./components/ProtectedRoutes";
import InboxPage from "./pages/User/InboxPage";
import Cookiebar from "./components/Cookies/CookieBar";
import Cookies from "js-cookie";
import InvoicePage from "./pages/User/InvoicePage";

function App() {

	const [sideNavOpened, setSideNavOpened] = useState<string | undefined>();

	const toggleNav = () => {
		const newStateOpened = sideNavOpened === "true" ? "false" : "true";
		Cookies.set("navOpened", newStateOpened);
		setSideNavOpened(newStateOpened);
	}

	return (
		<div className="App">
			<Cookiebar />
			<Navbar handleClose={toggleNav} />
			<Routes>
				<Route element={<ProtectedRoutes />}>
					<Route path="/dashboard" element={<Dashboard />}></Route>
					<Route path="/users/:userId/garage" element={<Garage />}></Route>
					<Route path="/users/:userId/calendar" element={<Calendar />}></Route>
					<Route path="/account/:userId" element={<Account />}></Route>
					<Route path="/users/:userId/inbox" element={<InboxPage />}></Route>
					<Route path="/users/:userId/invoices" element={<InvoicePage />}></Route>
				</Route>
				<Route path="/" element={<Login />}></Route>
				<Route path="/signup" element={<Signup />}></Route>
			</Routes>
		</div >
	);
}

export default App;
