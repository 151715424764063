import React from 'react'
import { UserChats } from '../../types/UserChats'
import { Box, IconButton, InputBase, Typography, alpha, styled } from '@mui/material';
import Cookies from 'js-cookie';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserChat from './UserChat';

type Props = {
    userChats: UserChats[] | undefined;
    onSelectedChat: (chat_id: number) => void;
    onUpdateFavorite: (updated_chat: UserChats) => void;
    onDeleteChat: (chat_id: number) => void;
}


const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
}));


const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));


const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '14ch',
            },
        },
    },
}));



export default function UserChatList({ userChats, onSelectedChat, onUpdateFavorite, onDeleteChat }: Props) {

    const open = Cookies.get("navOpened");

    return (
        <Box height={"85%"} width={"100%"}>
            <Box sx={{
                padding: open === "true" ? "20px 0 0 260px" : "20px 0 0 80px",
                minHeight: "8vh",
                height: "8vh",
                transition: "padding-left 0.3s ease-in-out"
            }}>
                <Box sx={{ justifyContent: "space-between", alignItems: 'center', display: "flex" }}>
                    <Typography variant='body1'>
                        All Messages
                        <IconButton size='small'>
                            <ExpandMoreIcon fontSize='medium' />
                        </IconButton>
                    </Typography>
                    <Box mr={2}>
                        <div>
                        <Search>
                            <SearchIconWrapper>
                            <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                            placeholder="Search…"
                            inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                        </div>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                padding: open === "true" ? "0 0 0 240px" : "0 0 0 65px",
                borderTop: "2px solid #f0f2f5",
                transition: "padding-left 0.3s ease-in-out",
            }}>
                {
                    userChats &&
                    userChats.map((userChat: UserChats) => (
                        <div key={userChat.user_chat_id} onClick={() => onSelectedChat(userChat.user_chat_id)}>
                            <UserChat key={userChat.user_chat_id} userChat={userChat} onUpdateFavorite={onUpdateFavorite} onDeleteChat={onDeleteChat} />
                        </div>
                    ))
                }
            </Box>
        </Box>
    )
}