import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { CalendarAppointment } from "../../../types/CalendarAppointment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteAppointmentDialog from "../ConfirmDelete/DeleteAppointmentDialog";
import { ServiceDetails } from "../../../types/ServiceDetails";
import axios from "axios";
import { useUser } from "../../../context/AuthContext";
import Cookies from "js-cookie";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type AppointmentDialogProps = {
  open: boolean;
  handleClose: () => void;
  setOpenAppDialog: (state: boolean) => void;
  appointment: CalendarAppointment | undefined;
  serviceDetails: ServiceDetails | undefined;
  handleDelete: (appointment: CalendarAppointment | undefined) => void;
  reloadAppointment: (appointment: CalendarAppointment | undefined) => void;
};

export default function AppointmentDialog({
  open,
  handleClose,
  appointment,
  serviceDetails,
  handleDelete,
  reloadAppointment,
  setOpenAppDialog
}: AppointmentDialogProps) {

  const { user } = useUser();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const currentDate = new Date();
  const [notes, setNotes] = useState<string>("");

  const daysDifference = Math.ceil(
    (new Date(appointment?.appointment_date || currentDate).getTime() -
      currentDate.getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const handleCloseDelete = () => 
  {
    setOpenDeleteDialog(false);
  };

  const handleUpdateAppointment = async () => {
    try 
    {
      const body = 
      {
        notes,
        appointment,
      };

      const isUpdated: boolean = appointment?.notes !== notes;
      if (isUpdated)
      {
        const token = Cookies.get("jwt");

        const response = await axios.put(
            `/api/users/${user?.user_id}/apppointments/${appointment?.appointment_id}`,
            JSON.stringify(body),
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          if (response.status === 200) {
            const updatedAppointment = { ...appointment, notes: response.data.appointment.notes } as CalendarAppointment;
            setNotes(response.data.appointment.notes);
            reloadAppointment(updatedAppointment);
            setOpenAppDialog(false);
          }
      }
      setOpenAppDialog(false);
    } catch (error) 
    {
      console.log(error);
    }
  };

  useEffect(() => {
    setNotes(appointment?.notes || '')
  },[appointment])

  return (
    <div>
      {appointment && serviceDetails && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Appointment Details</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {" "}
              Vehicle: {appointment.Car.year} {appointment.Car.make}{" "}
              {appointment.Car.model}
            </DialogContentText>
            <DialogContentText>
              Service: {serviceDetails.service_name}
            </DialogContentText>
            <DialogContentText>
              Date: {new Date(appointment.appointment_date).toLocaleString()}
            </DialogContentText>
            <Typography sx={{ mt: 2 }}>Notes</Typography>
            <TextField
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNotes(e.target.value)
              }
              defaultValue={notes}
              fullWidth
              multiline
              rows={4}
            ></TextField>
            <Box width="100%" sx={{ textAlign: "center", mt: 2 }}>
              <Button
                disabled={daysDifference <= 1}
                startIcon={<HighlightOffIcon />}
                variant="contained"
                color="error"
                onClick={() => setOpenDeleteDialog(true)}
              >
                Cancel Appointment
              </Button>
              <DeleteAppointmentDialog
                appointment={appointment}
                handleClose={handleCloseDelete}
                handleCloseParent={handleClose}
                open={openDeleteDialog}
                handleDelete={() => handleDelete(appointment)}
              ></DeleteAppointmentDialog>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
            <Button variant="contained" onClick={handleUpdateAppointment}>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
