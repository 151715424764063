import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

import "./Calendar.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { CalendarAppointment } from "../../types/CalendarAppointment";
import Cookies from "js-cookie";


export default function Calendar() 
{  
  const token = Cookies.get("jwt");
  const { userId } = useParams();
  const [appointments, setAppointments] = useState<CalendarAppointment[]>([]);

  const loadAppointments = async () => {
    try {
      await axios.get(
        `/api/users/${userId}/calendar`, {
          headers:{
            Authorization: `Bearer ${token}`
          }
        }
      )   
    }
    catch(error) {
      console.log(error);
    }
  }


  useEffect(() => {
    loadAppointments();
  }, [])

  return (
    <div className="calendar-wrapper">
      <div className="calendar">
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
        ></FullCalendar>
      </div>
    </div>
  );
}
