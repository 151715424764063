import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { Box, Button, CssBaseline, Fade, Paper, Stack, Typography } from '@mui/material';
import TrapFocus from '@mui/material/Unstable_TrapFocus';

type Props = {}

export default function Cookiebar({ }: Props) {
	const [bannerOpen, setBannerOpen] = useState<boolean>(true);
	const [cookie, setCookie] = useState<string | undefined>(Cookies.get("cookieConsent"));
	const [selectedTab, setSelectedTab] = useState<string | undefined>(Cookies.get("selectedTab"));

	useEffect(() => 
	{
	}, [cookie]);


	const onAcceptCookies = () => 
	{		
		setCookie(Cookies.set("cookieConsent", "true")); 
		setCookie(Cookies.set("selectedTab", ""));
		setSelectedTab("");
		setBannerOpen(false);
	}


	const onRejectCookies = () => 
	{
		setBannerOpen(false);
		setCookie(Cookies.set("cookieConsent", "false"));
		setSelectedTab(Cookies.set("selectedTab", "undefined"));
	}


	return (
		<React.Fragment>
			<CssBaseline />
			{
				!cookie && (
					<TrapFocus open disableAutoFocus disableEnforceFocus>
					<Fade appear={false} in={bannerOpen}>
						<Paper
							role="dialog"
							aria-modal="false"
							aria-label="Cookie banner"
							square
							variant="outlined"
							tabIndex={-1}
							sx={{
								position: 'fixed',
								bottom: 0,
								left: 0,
								right: 0,
								m: 0,
								p: 2,
								borderWidth: 0,
								borderTopWidth: 1,
							}}
						>
							<Stack
								direction={{ xs: 'column', sm: 'row' }}
								justifyContent="space-between"
								gap={2}
							>
								<Box
									sx={{
										flexShrink: 1,
										alignSelf: { xs: 'flex-start', sm: 'center' },
									}}
								>
									<Typography fontWeight="bold">Privacy</Typography>
									<Typography variant="body2">
										We use cookies to personalize our website and offerings to your interests and for measurement and analytics purposes. By using our website and our products, you agree to our use of cookies.
									</Typography>
								</Box>
								<Stack
									gap={2}
									direction={{
										xs: 'row-reverse',
										sm: 'row',
									}}
									sx={{
										flexShrink: 0,
										alignSelf: { xs: 'flex-end', sm: 'center' },
									}}
								>
									<Button size="small" onClick={onAcceptCookies} variant="contained">
										Allow all
									</Button>
									<Button size="small" onClick={onRejectCookies}>
										Reject all
									</Button>
								</Stack>
							</Stack>
						</Paper>
					</Fade>
				</TrapFocus>
				)
			}
		</React.Fragment>
	)
}