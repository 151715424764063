import { UserChats } from '../../types/UserChats'
import MessageRenderer from './MessageRenderer';
import { DbUser } from '../../context/AuthContext';
import ComposeMessage from './ComposeMessage';
import { Message } from '../../types/Message';

type Props = {
    chats: UserChats | undefined;
    user: DbUser | null;
    onCreateNewMessage: (chat: Message) => void;
    messages: Message[];
}

export default function ChatList({ chats, user, onCreateNewMessage, messages }: Props) {
  return (
    <div>
        <MessageRenderer chats={chats} user={user} messages={messages}></MessageRenderer>
        <ComposeMessage onCreateNewMessage={onCreateNewMessage} chatId={chats?.user_chat_id} user={user}/>
    </div>
  )
}