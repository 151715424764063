import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import {
    LocalizationProvider,
    StaticDatePicker,
    TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./ScheduleAppointment.scss";
import dayjs from "dayjs";
import { ServiceName } from "../../../pages/User/Garage";


type AppointmentProps = {
    handleConfirmAppointment: () => void;
    handleDateChange: (date: Date) => void;
    setNotes: (input: string) => void;
    setTime: (value: any) => void;
    handleClose: () => void;
    handleServiceChange: (value: ServiceName) => void;
    selectedService: ServiceName | null;
    services: ServiceName[];
    open: boolean;
};


export default function ScheduleAppointment({
    open,
    handleClose,
    handleDateChange,
    setTime,
    handleConfirmAppointment,
    handleServiceChange,
    setNotes,
    selectedService,
    services,
}: AppointmentProps) {


    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
            <DialogTitle>
                <Typography fontSize={"1.25rem"}>Schedule Appointment</Typography>
            </DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
                    <StaticDatePicker
                        onChange={(newValue: any) => handleDateChange(newValue)}
                        orientation="landscape"
                        minDate={dayjs().add(1, 'day')}
                        disablePast
                        slotProps={{ actionBar: { actions: [] } }}
                    ></StaticDatePicker>
                    <div className="time-picker-container">
                        <TimePicker onChange={(newValue) => setTime(newValue)}></TimePicker>
                    </div>
                </LocalizationProvider>
                <div className="service-picker-container">
                    <FormControl fullWidth>
                        <InputLabel>Service Type</InputLabel>
                        <Select
                            value={selectedService?.service_name ? selectedService.service_name : ''}
                            label="Service Type"
                            onChange={(e: SelectChangeEvent) => 
                            {
                                const selectedService = services
                                    .find(service => service.service_name === e.target.value);      
                                                             
                                if (selectedService) {
                                    handleServiceChange(selectedService ?? null);
                                }
                            }}
                        >
                            {services &&
                                services.map((service) => {
                                    return (
                                        <MenuItem value={service.service_name} key={`${service.service_id}-${service.service_name}`}>{service.service_name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
                <TextField
                    multiline
                    fullWidth
                    rows={4}
                    placeholder="Notes"
                    label="Notes"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setNotes(e.target.value)
                    }
                ></TextField>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleConfirmAppointment}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
