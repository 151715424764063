import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { UserChats } from '../../types/UserChats'
import PersonIcon from '@mui/icons-material/Person';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';
import { useUser } from '../../context/AuthContext';
import { useState } from 'react';
import Cookies from 'js-cookie';

type Props = {
    userChat: UserChats | undefined;
    onUpdateFavorite: (updated_chat: UserChats) => void;
    onDeleteChat: (chat_id: number) => void;
}

export default function UserChat({ userChat, onUpdateFavorite, onDeleteChat }: Props) 
{

    const { user } = useUser();
    const [isFavorite, setIsFavorite] = useState<boolean>(userChat?.is_favorite ?? true);
    const token = Cookies.get("jwt");
    const isRecipient = user?.user_id === userChat?.recipient_id;

    const chatTitleName = isRecipient
    ? `${userChat?.sender.first} ${userChat?.sender.last}`
    : `${userChat?.recipient.first} ${userChat?.recipient.last}`;


    const onFavoriteChat = async () => 
    {
        setIsFavorite(!isFavorite);
        const body = 
        {
            userChatId: userChat?.user_chat_id,
            isFavorite: !isFavorite
        };

        const token = Cookies.get("jwt");

        try 
        {
            const response = await axios.put(`/api/users/${user?.user_id}/chats/favorite`, 
            JSON.stringify(body),
            {
                headers: 
                {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            const updatedUserChat = response.data.userChat;
            if (updatedUserChat)
            {
                onUpdateFavorite(updatedUserChat);
            }

        } 
        catch (error) 
        {
            
        }
    };

    const deleteChat = async() =>
    {
        try 
        {
            const response = await axios.delete(
                `/api/users/${user?.user_id}/chats/${userChat?.user_chat_id}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
            );
            if (response.status === 200 && userChat)
            {
                onDeleteChat(userChat.user_chat_id);
            }
        } 
        catch (error) 
        {
            console.log(error); 
        }
    }

    
    return (
        <Grid container sx={{
            height: "150px",
            display: "flex",
            alignItems: "center",
            ":hover": {
                backgroundColor: "#f0f2f5",
                transition: "background-color 0.1s ease",
                cursor: "pointer"
            }
        }}>
            <Grid item pl={2} xs={4} sm={2}>
                <Avatar>
                    <PersonIcon fontSize='large' />
                </Avatar>
            </Grid>
            <Grid item height={"100%"} xs={4} sm={8}>
                <Box sx={{ rowGap: "10px" }} height="100%" display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Typography fontWeight={500} variant='body1'>{`${chatTitleName}`}</Typography>
                    <Box>
                        <IconButton size='small'>
                            <FileDownloadIcon fontSize='small' color='primary' />
                        </IconButton>
                        <IconButton size='small' onClick={deleteChat}>
                            {
                                userChat &&
                                <DeleteIcon fontSize='small' color='primary' /> 
                            }
                        </IconButton>
                        <IconButton size='small'>
                            <MarkAsUnreadIcon fontSize='small' color='primary' />
                        </IconButton>
                        <IconButton size='small'>
                            <PersonAddAlt1Icon fontSize='small' color='primary' />
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item height={"100%"} xs={4} sm={2}>
                <Box height="100%" display={"flex"} 
                    flexDirection={"column"} 
                    justifyContent={"center"} 
                    alignItems={"center"}>
                    {
                        userChat &&
                        <Typography variant='body2'>{`${new Date(userChat.last_sent_message).toLocaleDateString('en-us', {
                            month: '2-digit',
                            day: '2-digit'
                        })}`}</Typography>
                    }
                    <IconButton size='small' onClick={onFavoriteChat}>
                        <StarIcon fontSize='small' color={userChat?.is_favorite ? "warning": "inherit"} />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    )
}