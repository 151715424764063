import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ReplyIcon from "@mui/icons-material/Reply";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/AuthContext";
import { Typography, useRadioGroup } from "@mui/material";
import Cookies from "js-cookie";
import { useState } from "react";
import SideNavTabItem from "./SideNavTabItem";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),

	...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

type SideNavProps = {
	handleClose: () => void;
	open: string | undefined;
}


type TabItem = {
	tabName: string;
	navigationPath: string;
}



const routes =
{
	dashboard: `/dashboard`,
	garage: `/garage`,
	calendar: `/calendar`,
	invoices: `/invoices`,
	messages: `/inbox`,
	account: `/account`
};


export default function SideNav({ handleClose, open }: SideNavProps) 
{

	const theme = useTheme();
	const navigate = useNavigate();
	const user = localStorage.getItem("userData");
	const userData = user ? JSON.parse(user) : null;
	const { logout } = useUser();
	const [selectedTab, setSelectedTab] = useState<string | undefined>(Cookies.get("selectedTab"));


	const userBasePath = `/users/${userData.user_id}`;


	const TabNavigationItems: TabItem[] = [
		{
			tabName: "Dashboard",
			navigationPath: routes.dashboard
		},
		{
			tabName: "Garage",
			navigationPath: `${userBasePath}${routes.garage}`
		},
		{
			tabName: "Calendar",
			navigationPath: `${userBasePath}${routes.calendar}`
		},
		{
			tabName: "Invoices",
			navigationPath: `${userBasePath}${routes.invoices}`
		},
		{
			tabName: "Inbox",
			navigationPath: `${userBasePath}${routes.messages}`
		},
		{
			tabName: "Account",
			navigationPath: `${routes.account}/${userData.user_id}`
		}
	]

	const handleLogout = () => {
		try 
		{
			logout();
		} 
		catch (error: any) 
		{
			console.log(error);
		}

		const token = Cookies.get("jwt");

		if (!token) 
		{
			navigate("/");
		}
	};


	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			<Drawer variant="permanent" open={open === "true"}>
				<DrawerHeader>
					{" "}
					{open === "true" && (
						<Typography variant="body1" fontSize={"1rem"} margin={"auto"}>
							Hello {userData.first}
						</Typography>
					)}
					<IconButton onClick={handleClose}>
						{theme.direction === "rtl" ? (
							<ChevronRightIcon />
						) : (
							<ChevronLeftIcon />
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List>
					<>
						{
							TabNavigationItems.map((tab: TabItem, index: number) => (
							<SideNavTabItem key={index} tabName={tab.tabName} navigationPath={tab.navigationPath} open={open} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
						))}
					</>
				</List>
				<Divider />
				<List>
					<ListItem disablePadding sx={{ display: "block" }}>
						<ListItemButton
							sx={{
								minHeight: 48,
								justifyContent: open ? "initial" : "center",
								px: 2.5,
							}}
							onClick={handleLogout}
						>
							<ListItemIcon
								sx={{
									minWidth: 0,
									mr: open ? 3 : "auto",
									justifyContent: "center",
								}}
							>
								<ReplyIcon />
							</ListItemIcon>
							<ListItemText primary={"Logout"} sx={{ opacity: open ? 1 : 0 }} />
						</ListItemButton>
					</ListItem>
				</List>
			</Drawer>
		</Box>
	);
}
