import React from "react";
import { Avatar } from "@mui/material";
import "./RoundedProfilePicture.scss"


interface ProfilePictureProps {
    imageURI: string
}

const RoundedProfilePicture: React.FC<ProfilePictureProps> = ({imageURI}: ProfilePictureProps) => {
    return (
        <Avatar alt="Profile Picture" src={imageURI} sx={{height: "100px", width: "100px"}} className="avatar"></Avatar>
    )
}

export default RoundedProfilePicture;