import { Box, Grid, IconButton, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

type Props = {
  onComposeChat: () => void;
}


export default function InboxHeader({ onComposeChat }: Props) 
{

  const open = Cookies.get("navOpened");

  return (
    <Box sx={{
        width: "100%",
		    minHeight: "100px",
        backgroundColor: "#f0f2f5",
      }}>
        <Grid container sx={{
          padding: open === "true" ? "10px 0 0 260px" : "10px 0 0 90px",
          transition: "padding-left 0.3s ease-in-out",
        }}>
            <Grid item xs={6} alignItems={"center"}>
                <Typography variant='h4'>Inbox</Typography>
            </Grid>
			<Grid item xs={6} display={"flex"} justifyContent={"flex-end"} padding={"1rem 1.25rem 0 0"}>
					<IconButton color='primary' sx={{backgroundColor: "white", mr: "10px"}} onClick={onComposeChat}>
						<BorderColorIcon />
					</IconButton>
					<IconButton color='primary'sx={{backgroundColor: "white"}}>
						<MoreHorizIcon />
					</IconButton>
			</Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"}>
                <Typography variant='body2'>View and reply to all your messages via chat here.</Typography>
            </Grid>
        </Grid>
    </Box>
  )
}